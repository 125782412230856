/**-------------------------------------------------------------
1.2.4.1 EV쿠폰 메인화면_3 - 쿠폰미등록
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/coupon.html
-------------------------------------------------------------*/

/* eslint-disable */


// 기본
import { useState, useEffect, useCallback, ChangeEvent, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Sheet from "react-modal-sheet";

//헤더 
import { Header } from '../../components/Layout';
import { globalVars } from '../../libs/global-vars';
import { HandleBrowserBackButton } from '../../components/Layout'

import Const from '../../store/ev'

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getCouponKey, patchCouponActive, postCoupon } from '../../apis/coupon';
import { getUser, postUser } from '../../apis/user';
import { modal } from '../../components/GlobalModal';

import AES from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import encBase64 from "crypto-js/enc-base64";
import padPkcs7 from "crypto-js/pad-pkcs7";
import ECB from "crypto-js/mode-ecb"; // ECB 모드 추가
import { patchAgree } from '../../apis/agreement';
import { store } from '../../store';
import { tmapInterface } from '../../libs/tmap-interface';




let scrollCache = 0;
let cvcAgreeCheck;
let userData: any = {};    // 회원정보조회(단건)

function CouponRegister(props: RouteComponentProps<{ id: string, productType: string }>) {
    const [couponList, setCouponList] = useState([{ couponCode: "" }]);
    const [isDisabled, setIsDisabled] = useState(true); // 선택한 쿠폰 적용하기 버튼
    const [inited] = useState(false);
    const [cvcSheet, setCvcSheet] = useState(false);
    const [sendCouponCode, setSendCouponCode] = useState(""); //쿠폰 코드 값
    const [agreeCheck, setAgreeCheck] = useState(true);
    const [btnFocus, setBtnFocus] = useState(false);
    const [sendCvc, setSendCvc] = useState('');
    const [cvcInputDisable, setCvcInputDisable] = useState(true);
    const [postReqStatus, setPostReqStatus] = useState(false);
    const [firstClick, setFirstClick] = useState(false);
    const [isPC, setIsPC] = useState(false);

    const [pressed, setPressed] = useState({ checkbox: false });

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);
    const [couponType, setCouponType] = useState(0);

    cvcAgreeCheck = store.getState().app.cvcAgree;

    const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        const list = [...couponList];
        list[index]["couponCode"] = value.replace(/-/,'');
        if (value.length > 0) {
            setIsDisabled(false);
            setSendCouponCode(value);
        } else {
            setIsDisabled(true);
        }
        setCouponList(list);
    };

    const onChangeCvc = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        
        if (value.length > 0) {
            setCvcInputDisable(false);
            setSendCvc(value);
        } else {
            setCvcInputDisable(true);
        }
    };

    useEffect(() => {
        mixpanel("evcharge:/evcharge/addcoupon");
        logbox("/evcharge/addcoupon", "");
    }, [])

    const couponPost = (cpoCode?: string) => {
        if (firstClick) return;

        if (cpoCode === "PC") {
            setIsPC(true);
        }

        setIsDisabled(true);
        setPostReqStatus(true);
        
        var data = {
            productCode: sendCouponCode,
            encryptedCardPassword: cpoCode === "PC" ? encryptData(sendCvc) : null
        };

        postCoupon(data).then(function (response: any) {
            setSendCouponCode("");
            setSendCvc("");
            setCvcSheet(false);
            // if (cpoCode === "PC") {
                
            // } else {
                if (response.result) { // 정상적으로 쿠폰 추가
                    mixpanel("evcharge:/evcharge/addcoupon:view.successcoupon_popup");
                    logbox("/evcharge/addcoupon", "tap.successcoupon_popup");

                    modal.alert(
                        () => {
                            setCouponType(response.couponType);
                        },
                        '쿠폰이 등록되었습니다.',
                    );
                } else {
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");
                    
                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                }
            // }
        })
        .catch(function (error: any) {
            setSendCouponCode("");
            setSendCvc("");
            setCvcSheet(false);
            setFirstClick(false);
            switch (error?.response?.data?.reason) {
                case "EV-PRODUCT-VALIDATION-FAIL-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.registcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.registcoupon_popup");

                    modal.alert(
                        () => registPop("regist"),
                        '이미 등록된 쿠폰입니다.',
                    );
                    break;
                case "EV-PRODUCT-DATE-INVALID-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.dateendcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.dateendcoupon_popup");

                    modal.alert(
                        () => registPop("end"),
                        '등록 유효기간이 만료된 쿠폰입니다.',
                    );
                    break;
                case "EV-PRODUCT-VALIDATION-FAIL-002":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                case "EV-ESS-REQ-FAIL-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                case "EV-PRODUCT-11ST-FAIL-002":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                case "EV-PRODUCT-11ST-FAIL-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                default:
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
            }
        });
    }

    const openPCApp = () => {
        let fallbackTimeout;

        const onVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                // 페이지가 숨김 상태가 되면 앱이 성공적으로 열렸다고 판단
                clearTimeout(fallbackTimeout);
                document.removeEventListener('visibilitychange', onVisibilityChange);
            }
        };

        fallbackTimeout = setTimeout(() => {
            // 앱이 1.5초 내에 열리지 않으면 스토어로 이동
            tmapInterface.openBrowser(
                globalVars.isIOS
                    ? "https://apps.apple.com/kr/app/id978597106"
                    : "https://play.google.com/store/apps/details?id=kr.co.iparking.android"
            );
        }, 1500); // 1.5초

        // 커스텀 URL 스킴을 사용하여 앱 열기 시도
        tmapInterface.openBrowser("pacl://evcharge?callerScheme=tmap");

        // 페이지 숨김 여부를 확인하여 앱이 열렸는지 판단
        document.addEventListener('visibilitychange', onVisibilityChange);
    };


    // couponList로 인해 젠킨스 빌드 시 오류 발생. 확인 필요
    // 쿠폰등록 팝업
    const registerCoupon = () => {
        mixpanel("evcharge:/evcharge/addcoupon:tap.add");
        logbox("/evcharge/addcoupon", "tap.add");

        setIsDisabled(true);

        getCouponKey(sendCouponCode).then((res:any) => {
            if (res.result && res.productKey === "PC-POINT-001") {
                setCvcSheet(true); //cvc 입력창 open
            } else {
                couponPost(); //일반 쿠폰 등록씬
            }
        }).catch((err) => {
            console.log(err)
        })
    };

    useEffect(() => {
        if (couponType !== 0) {
            registPop("success");
        }
    },[couponType])

    const registPop = (status: any) => {
        switch (status) {
            case "success":
                mixpanel("evcharge:/evcharge/addcoupon:tap.successcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.successcoupon_ok");
                break;
            case "regist":
                mixpanel("evcharge:/evcharge/addcoupon:tap.registcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.registcoupon_ok");
                break;
            case "end":
                mixpanel("evcharge:/evcharge/addcoupon:tap.dateendcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.dateendcoupon_ok");
                break;
            default:
                mixpanel("evcharge:/evcharge/addcoupon:tap.wrongcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.wrongcoupon_ok");
        }

        if (globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') {
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
        }

        setCouponList([{ couponCode: "" }]);
        
        if (status === "success") {
            if (isPC) {
                openPCApp();
            } else {
                if (couponType === 1) {
                    goPointCoupon();
                } else {
                    goRateCoupon();
                }
            }
        }
    };

    const goPointCoupon = () => {
        props.history.replace('/pointCoupon');
    }
    const goRateCoupon = () => {
        props.history.replace('/rateCoupon');
    }

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.checked = agreeCheck;
        }
    }, [agreeCheck]);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
        const serviceType = globalVars.queryData.extra?.servicetype?.toLowerCase() || globalVars.queryData.extra?.serviceType?.toLowerCase();

        const cardNumber = globalVars.queryData.extra?.payLoad?.cardNumber || globalVars.queryData.extra?.payload?.cardNumber;
        const activation = (globalVars.queryData.extra?.payLoad?.activation || globalVars.queryData.extra?.payload?.activation || '').replace(/\s/g, '');

        if (serviceType === 'couponregister') {
            if (cardNumber) {
                setSendCouponCode(cardNumber as string);
                setIsDisabled(true);
                // if (activation === 'y') { //activation true면
                patchCouponActive({ productCode: cardNumber, activeYn: activation === 'y' ? true : false })
                    .then((res: any) => {
                        console.log(res);
                        // 쿠폰 상태 업데이트가 성공하면 goPointCoupon 호출
                        if (activation === 'y') {
                            goPointCoupon();
                        }
                    })
                    .catch((err) => {
                        goPointCoupon(); // 실패하더라도 백화, 실패 화면 대신 포인트쿠폰 화면으로 랜딩
                        console.log(err);
                    });
            } else {
                setSendCouponCode((globalVars.queryData.extra?.payLoad || globalVars.queryData.extra?.payload) as string)
            }
            if (globalVars.accessKey) {

                if (Const.DBG_OK) {
                    console.log("#2 AccessKey 검증 및 이상없으면 회원정보 리턴");
                }

                getUser()
                    .then(function (response: any) {
                        // 기존회원이므로 회원정보 받기
                        if (response.result) {
                            userData = response;

                            if (Const.DBG_OK) {
                                // console.log(JSON.stringify(response.data));
                                alert("사용자정보 " + JSON.stringify(userData));
                            }

                            // 미등록회원이므로 DB에 등록
                        }
                    })
                    .catch(function (error: any) {
                        if (error.response.status === 400 || error.response.data.result === false) {
                            // 신규회원등록
                            var data = {};

                            postUser(data)
                                .then(function (response: any) {
                                    if (Const.DBG_OK) {
                                        // console.log('신규회원등록')
                                        alert("신규회원등록 " + JSON.stringify(response.data));
                                    }

                                    getUser()
                                        .then(function (response: any) {
                                            userData = response;

                                            if (Const.DBG_OK) {
                                                // console.log('신규회원정보 조회성공')
                                                alert("신규회원 " + JSON.stringify(response.data));
                                            }
                                        })
                                        .catch(function (error: any) {
                                            //							alert('신규회원정보 조회실패')
                                            console.log(error);
                                        });
                                })
                                .catch(function (error: any) {
                                    console.log(error);
                                });
                        }
                    });
            } else {
                if (Const.DBG_OK) {
                    alert("ACCESS KEY 없는경우 - 접속 제한 ()");
                    //props.history.replace("/notFound");
                }
                props.history.replace("/notFound");
            }
        }
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    const resPayLoad = () => {
        const { payLoad, payload } = globalVars.queryData.extra || {} ;

        if (payLoad !== undefined && payLoad as string !== "") {
            if (payLoad?.cardNumber) {
                return payLoad.cardNumber as string;
            } else {
                return payLoad;
            }
        }

        if (payload !== undefined && payload as string !== "") {
            if (payload?.cardNumber) {
                return payload.cardNumber as string;
            } else {
                return payload;
            }
        }
    };

    const enterRegist = (e) => {
        if (e.key == 'Enter' || e.keyCode == 13) {
            e.preventDefault();
            e.target.blur();
            // return false;
        }
    };

    // const onChangeCheck = () => {
    //     if (agreeCheck === false) {
    //         setAgreeCheck(true);
    //     } else {
    //         setAgreeCheck(false);
    //     }
    // }

    const onChangeCheck = (e) => {
        const isChecked = e.target.checked;
        setAgreeCheck(isChecked);

        // 강제로 리렌더링 유도 (이 부분이 중요)
        requestAnimationFrame(() => {
            if (checkboxRef.current) {
                checkboxRef.current.checked = isChecked;
            }
        });
    };
    
    const encryptData = (plainText: string): string => {
        const key = encUtf8.parse(process.env.REACT_APP_AES_KEY as string);

        const encrypted = AES.encrypt(plainText, key, {
            mode: ECB,
            padding: padPkcs7,
        });

        // Base64 형식으로 변환
        const base64Encrypted = encBase64.stringify(encrypted.ciphertext);

        return base64Encrypted;
    };
    
    const handleRegisterCard = () => {
        setFirstClick(true);

        const agreebody = {
            "agreementType": 2,
            "agreementCode": 1,
            "agreementYn": true
        }

        if (!cvcAgreeCheck && !postReqStatus) {
            patchAgree(agreebody).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })
        }

        couponPost("PC"); //pc쿠폰 등록
    }

    const inputRef = useRef<HTMLInputElement>(null);

    const handleBackdropClick = () => {
        inputRef.current?.blur(); // 입력 필드의 포커스를 해제

        if (globalVars.isAndroid) {
            setTimeout(() => {
                setCvcSheet(false)
            }, 200)
        } else {
            setCvcSheet(false)
        }
        setIsDisabled(false);
    };

    const [showAgreementDetails, setShowAgreementDetails] = useState(false); // 제3자 동의 상세 내용 보기 상태


    const checkboxRef = useRef<HTMLInputElement | null>(null);


    const handleCheckboxChange = () => {
        setAgreeCheck(prevCheck => !prevCheck);
        console.log('Checkbox state changed:', !agreeCheck);
    };

// 라벨을 클릭했을 때 처리할 함수
    const handleLabelClick = (e) => {
        e.preventDefault(); // 기본 체크박스 토글 방지
        setShowAgreementDetails(true); // 모달 열기
        console.log('Label clicked');
        // 라벨 클릭 시의 추가 로직을 여기에 작성합니다.
    };

    const closeAgreementModal = () => {
        setShowAgreementDetails(false); // 모달 닫기
    };

    const handleTouchStart = () => {
        setPressed({ checkbox: true });
    };

    const handleTouchEnd = (e) => {
        e.preventDefault(); // 기본 동작 방지
        setAgreeCheck((prevCheck) => !prevCheck);
        console.log('Touch event triggered for checkbox');

        // iOS 환경에서 상태 변경을 강제로 업데이트
        requestAnimationFrame(() => {
            if (checkboxRef.current) {
                checkboxRef.current.checked = !agreeCheck;
            }
        });
    };

    return (
        <>
            <Header title={'EV 쿠폰 등록'} isMenu={false} isActive={'3'} />
            {<HandleBrowserBackButton />}

            <div className="coupon-register">
                <div className='coupon-regist-title'>쿠폰 등록</div>
                <div className="register-box">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <fieldset style={{ display: "flex"}}>
                            <div className="field">
                                {couponList.map((x, i) => (
                                    <input
                                        key={i}
                                        type="text"
                                        name="couponCode"
                                        placeholder="쿠폰 코드를 입력해주세요."
                                        value={x.couponCode}
                                        onChange={e => onChange(e, i)}
                                    />
                                ))}
                            </div>
                            <div className="coupon-add-btn" style={{zIndex:"9"}}>
                                <button type="button" onClick={registerCoupon} disabled={isDisabled}>등록</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                {!showAgreementDetails && (
                    <Sheet
                        isOpen={cvcSheet}
                        onClose={() => handleBackdropClick()}
                        snapPoints={[350, 0]}
                        disableDrag={true}
                    >
                        <Sheet.Container style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px', overflow: 'hidden' }}>
                            <Sheet.Content>
                                <div style={{ textAlign: 'center', letterSpacing: 'normal', paddingTop: '50px' }} className='cvc-box'>
                                    <div style={{ fontSize: '20px', fontFamily: 'TMOBI500', marginBottom: '10px' }}>CVC 번호 입력</div>
                                    <div style={{ fontSize: '16px', fontFamily: 'TMOBI300', color: '#8C9299' }}>CVC 번호를 입력해주세요</div>
                                    <input
                                        type="number"
                                        name="cvcNum"
                                        onChange={e => onChangeCvc(e)}
                                        style={{
                                            borderBottom: '2px solid #111',
                                            fontFamily: 'TMOBI500',
                                            fontSize: '24px',
                                            marginTop: '20px',
                                            textAlign: 'center',
                                            outline: 'none',
                                            width: '70%',
                                            letterSpacing: 'normal',
                                            padding: '0 0 10px 5px',
                                        }}
                                        className="no-arrow"
                                    />
                                    <p className="check" style={{ marginTop: '40px', textAlign: 'left', padding: '0 30px', display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id="chk2"
                                            ref={checkboxRef}
                                            checked={cvcAgreeCheck || agreeCheck}
                                            onChange={onChangeCheck}
                                            onTouchEnd={handleTouchEnd} // 터치 이벤트도 처리
                                            style={{ marginRight: '10px' }}
                                        />
                                        <label
                                            htmlFor="chk2"
                                            style={{
                                                fontFamily: "TMOBI300",
                                                fontSize: "14px",
                                                cursor: 'pointer',
                                                opacity: pressed.checkbox ? 0.8 : 1 // 눌린 상태에서 약간의 시각적 피드백 제공
                                            }}
                                            onClick={handleLabelClick}
                                            // onTouchEnd={handleTouchEnd} // 모바일 터치 이벤트 처리
                                        >
                                            (필수) 개인정보 제 3자 제공 동의
                                        </label>
                                    </p>


                                    <div
                                        className={agreeCheck || cvcAgreeCheck && !cvcInputDisable ? 'footer-button' : 'dim-footer-button'}
                                        onClick={() => {
                                            agreeCheck || cvcAgreeCheck && !cvcInputDisable ? handleRegisterCard() : '';
                                        }}
                                    >
                                        <a
                                            style={btnFocus ? { filter: 'brightness(90%)' } : {}}
                                            onTouchStart={() => {
                                                agreeCheck || cvcAgreeCheck && !cvcInputDisable ? setBtnFocus(true) : '';
                                            }}
                                            onTouchEnd={() => setBtnFocus(false)}
                                        >
                                            등록하기
                                        </a>
                                    </div>
                                </div>
                            </Sheet.Content>
                        </Sheet.Container>
                        <Sheet.Backdrop style={{ opacity: '0.1' }} onTap={() => handleBackdropClick()} />
                    </Sheet>
                )}
            </div>

            {showAgreementDetails && (
                <div
                    className="fullscreen-modal"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1100, // Sheet보다 높은 Z-index 값 설정
                    }}
                >
                    <div
                        className="modal-header"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 20px',
                            borderBottom: '1px solid #ddd',
                        }}
                    >
                        <button
                            onClick={closeAgreementModal}
                            style={{
                                background: 'none',
                                border: 'none',
                                fontSize: '18px',
                                marginRight: 'auto',
                            }}
                        >
                            &lt;
                        </button>
                        <h2
                            style={{
                                position: 'absolute',
                                width: '198px',
                                height: '24px',
                                left: 'calc(50% - 198px / 2)',
                                top: '16px',
                                fontFamily: 'TMOBI500',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                lineHeight: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                color: '#171819',
                                margin: 0,
                            }}
                        >
                            개인정보 제 3자 제공 동의
                        </h2>
                    </div>
                    <div style={{ padding: '20px' }}>
                        <AgreementDetails />
                    </div>
                </div>
            )}
        </>
    );
}

// 새로운 화면 컴포넌트 추가
function AgreementDetails() {
    return (
        <div className="agreement-details">
            <ul>
                <li>

                    <div style={{
                        position: 'absolute',
                        left: '72px',
                        width: '260px',
                        height: '1px',
                        top: '218px',
                        background: '#EEF0F3'
                    }}></div>
                    <div style={{
                        position: 'absolute',
                        left: '72px',
                        width: '260px',
                        height: '1px',
                        top: '260px',
                        background: '#EEF0F3'
                    }}></div>
                    <div style={{
                        position: 'absolute',
                        left: '72px',
                        width: '260px',
                        height: '1px',
                        top: '300px',
                        background: '#EEF0F3'
                    }}></div>
                    <div style={{
                        position: 'absolute',
                        left: '72px',
                        width: '260px',
                        height: '1px',
                        top: '357px',
                        background: '#EEF0F3'
                    }}></div>

                    <div className="txt" style={{
                        position: 'absolute',
                        width: '260px',
                        height: '60px',
                        left: '72px',
                        top: '132px',
                        fontFamily: 'TMOBI',
                        fontStyle: 'normal',
                        fontWeight: 250,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#8C9299',
                        mixBlendMode: 'normal'
                    }}>
                        티맵모빌리티 주식회사는 선불 충전권 등록 서비스 제공을 위해 아래와 같이 개인정보를 수집합니다.
                    </div>
                    <dl className="dl-style1">
                        <dt style={{
                            position: 'absolute',
                            width: '90px',
                            height: '16px',
                            left: '76px',
                            top: '231px',
                            fontFamily: 'TMOBI250',
                            fontStyle: 'normal',
                            // fontWeight: 250,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#8C9299'
                        }}>제공받는 회사</dt>
                        <dd style={{
                            position: 'absolute',
                            width: '142px',
                            height: '16px',
                            top: '231px',
                            left: '186px',
                            fontFamily: 'TMOBI500',
                            // fontWeight: 500,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#171819'
                        }}>(주)파킹클라우드</dd>
                        <dt style={{
                            position: 'absolute',
                            width: '90px',
                            height: '16px',
                            left: '76px',
                            top: '271px',
                            fontFamily: 'TMOBI250',
                            fontStyle: 'normal',
                            // fontWeight: 250,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#8C9299'
                        }}>제공 목적</dt>
                        <dd style={{
                            position: 'absolute',
                            width: '142px',
                            height: '16px',
                            top: '271px',
                            left: '186px',
                            fontFamily: 'TMOBI500',
                            // fontWeight: 500,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#171819'
                        }}>선불 충전권 등록</dd>
                        <dt style={{
                            position: 'absolute',
                            width: '90px',
                            height: '16px',
                            left: '76px',
                            top: '313px',
                            fontFamily: 'TMOBI250',
                            fontStyle: 'normal',
                            // fontWeight: 250,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#8C9299'
                        }}>제공 정보</dt>
                        <dd style={{
                            position: 'absolute',
                            width: '142px',
                            height: '32px',
                            top: '313px',
                            left: '186px',
                            fontFamily: 'TMOBI250',
                            // fontWeight: 500,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#171819'
                        }}>선불카드번호, 인증키, 거래고유번호</dd>
                        <dt style={{
                            position: 'absolute',
                            width: '90px',
                            height: '16px',
                            left: '76px',
                            top: '370px',
                            fontFamily: 'TMOBI250',
                            fontStyle: 'normal',
                            // fontWeight: 250,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#8C9299'
                        }}>보유/이용 기간</dt>
                        <dd style={{
                            position: 'absolute',
                            width: '142px',
                            height: '32px',
                            top: '370px',
                            left: '186px',
                            fontFamily: 'TMOBI500',
                            // fontWeight: 500,
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: '#171819'
                        }}>동의일로부터 서비스 종료 또는 개인정보 유효기간 도과 시까지 (단, 약관 및 관계 법령에 따라 회원의 개인정보를 보존하여야 하는 경우, 회사는 해당 법령에서 정한 기간동안 보관합니다.)</dd>
                    </dl>
                </li>
            </ul>
        </div>
    );
}

export { CouponRegister, AgreementDetails };