/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
// <!-- import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'; -->
import { useHistory } from "react-router";
import { HeaderBackButton } from './HeaderBackButton';
// import {HandleBrowserBackButton} from '../../components/Layout'
import { modal } from '../GlobalModal';
import { appActions, store } from "../../store";

import { globalVars } from '../../libs/global-vars';
//import axios from 'axios';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';

import Const from '../../store/ev';
import axios, { AxiosError, AxiosResponse } from "axios";
import { tmapInterface } from "../../libs/tmap-interface";
import { getDeliveryHistory } from '../../apis/delivery';


let isCouponPop = false;

function Header({ title, isMenu, isHistoryMenu, isInfoMenu, isActive }: { title?: string, isMenu?: boolean, isHistoryMenu?: boolean, isInfoMenu?: boolean, isActive?: string }) {
  const history = useHistory();
  const [menu, setMenu] = useState(isMenu);
  const [active, setActive] = useState(isActive);
  const [historyMenu, setHistoryMenu] = useState(isHistoryMenu);
  const [infoMenu, setInfoMenu] = useState(isInfoMenu);

  const [pressed, setPressed] = useState({
    evUse : false,
    deliveryUse : false
  })

  // const [isCouponPop, setIsCouponPop] = useState(false); //쿠폰 등록 팝업
  const [isBack, setIsBack] = useState(false);
  const [inited, setInited] = useState(false);

  const cancelBack = () => {

    isCouponPop = false;
    // setIsCouponPop(false);

    setIsBack(false);
  }

  const goMain = () => {
    isCouponPop = false;
    // setIsCouponPop(false);

    // history.push('/payMethod');
    history.replace('/payMethod');

  }

  const goChargeDeliveryHistory = () => {
    mixpanel("evcharge:/evcharge/chargehistory:tap.chargedelivery");
    logbox("/evcharge/chargehistory","tap.chargedelivery");

    getDeliveryHistory()
      .then((response: any) => {
        const objectHtml = document.createElement('div');
        objectHtml.innerHTML = response;
        console.log(objectHtml.querySelector('form')?.getAttribute('action'));

        tmapInterface.tChargeDelivery(objectHtml.querySelector('form')?.getAttribute('action') as string);
      })
      .catch((error: any) => {
        console.log(error);
      });

  };


  useEffect(() => {

    // let paths = "";
    // for (let i = 0; i < history.length; i++) {
    //   paths += " > " + history[i].location.pathname;
    // }

    // alert("히스토리: " + paths)

    // console.log("팝업 호출 : " + isCouponPop);
    // alert("팝업 호출 : " + isCouponPop);

    // history.block((location, action) => {
    //   alert(action + " " + location.pathname);
    // });


    setInited(true);

    // setIsBack(true);

    // if (isMenu === undefined) {
    //   setMenu(false);
    // }
    if (isActive === undefined) {
      setActive("1");
    }
    // if (isHistoryMenu === undefined) {
    //   setHistoryMenu(false);
    // }
    // if (isPncMenu === undefined) {
    //   setPncMenu(false)
    // }
  }, []);

  const onClickHeader = useCallback((url) => {

    if (url === "/main") {
      mixpanel("evcharge:/evcharge/main:tap.main");
      logbox("/evcharge/main","tap.main");
    } else if (url === "/chargeHistory") {
      mixpanel("evcharge:/evcharge/chargehistory:tap.chargehistory");
      logbox("/evcharge/chargehistory","tap.chargehistory");
    } else if (url === "/pointCoupon") {
      mixpanel("evcharge:/evcharge/evcoupon:tap.evcoupon");
      logbox("/evcharge/evcoupon","tap.evcoupon");
    }

    history.replace(`${url}`);
  }, [history]);

  if (!inited) {
    return null;
  } else {
    return (
      <header>

        <div className="header-top">
          <HeaderBackButton />
          <h3 className="header-title">{title || '전기차충전'}</h3>
          {
            (window.location.pathname === '/main')
              ?
              <Link to="/mainInfo" onClick={() => {
                mixpanel("evcharge:/evcharge/main:tap.useguide");
                logbox("/evcharge/main","tap.useguide");
              }}>이용안내</Link>
              :
              <></>
          }
        </div>


        {infoMenu && (
          <div className="tab-menu">
            {/* 메뉴 클릭시 active 임시 반영 */}
            <button type="button"
              className={active === '1' ? 'active' : ''}
              onClick={e => {
                e.stopPropagation();
                // alert(window.location.pathname);
                onClickHeader('/mainInfo');
              }}
            >QR충전</button>
            <button type="button"
              className={active === '2' ? 'active' : ''}
              onClick={e => {
                e.stopPropagation();
                onClickHeader('/pncInfo');
              }}
            >오토차지</button>
          </div>
        )}
      </header>
    );
  }
}
export { Header };