/**-------------------------------------------------------------
1.2.2.3 충전소 상세 페이지
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/qr_charger_complete.html
-------------------------------------------------------------*/

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom';

import { appActions, store } from '../../store';
import axios from 'axios';
import Const from '../../store/ev';
import moment from 'moment';
import { utils } from '../../libs/utils';
import "moment/locale/ko";
import { modal } from "../../components/GlobalModal";

import { globalVars } from '../../libs/global-vars';
import { tmapInterface } from "../../libs/tmap-interface";
import { appManager } from "../../managers";
import _, { flatten, groupBy, map, merge } from 'lodash';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { getTapCharge, getTapChargeRadius } from '../../apis/taptap';
import { getDriving } from '../../apis/poi';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getAuthStatus, getUserStatus } from '../../apis/status';
import { Header } from '../../components/Layout';

import Lottie from "lottie-react";
import QrAnimation from "./qr_icon.json";

let stationInfo: any = {}
let lon: any = {}
let lat: any = {}
let nearPoiId: any = {}
// 에버온:EV
// 환경부:ME
// 차지비:PI

function TapCharge(props: RouteComponentProps<{ x: string, poi: string, lon: string, lat: string }>) {
    const poi = props.match.params.poi;
    const lon = props.match.params.lon;
    const lat = props.match.params.lat;
    const isPolestar = store.getState().app.isPolestar;

    const history = useHistory();

    const [inited, setInited] = useState(false);
    // const [poiId, setPoiId] = useState('');
    // const [belongIdx, setBelongIdx] = useState('');
    const [roadData, setRoadData] = useState('');

    const [imgOpen, setImgOpen] = useState(false);

    const [seqList, setSeqList] = useState<any>([]);
    const [seqYN, setSeqYN] = useState(false);

    const [imgZoomStart, setImgZoomStart] = useState(false);

    const [focusCharger, setFocusCharger] = useState("");

    const [bPop, setBPop] = useState(false); //충전 시작 팝업
    const [chargeStart, setChargeStart] = useState([]) as any;
    const borderBottomStyle = { width: "90%", margin: "0 auto", borderBottom: "1px solid #F0F0F0" };

    const [chargerList, setChargerList] = useState({
        roadMap: "",
        orgName: "",
        fullAddressJibun: "",
        infoList: [
            {
                operatorId: "",
                operatorName: "",
                stationId: "",
                chargerId: "",
                status: "",
                chargerSpeed: "",
                type: "",
                powerType: "",
                chargingDateTime: "",
                updateDateTime: "",
                couplerSeq: "",
                stationImagePath: "",
                available: true,
                fast: true
            }
        ]
    });

    const [firstClick, setFirstClick] = useState(false);

    const [nearStation, setNearStation] = useState([]) as any;
    //pressed
    const [focusCurrntStatus, setFocusCurrentStatus] = useState(false);
    const [focusCharging, setFocusCharging] = useState(false);
    // const [focusNear, setFocusNear] = useState(false);
    const [pressed, setPressed] = useState({
        focusNear: false,
        focusPoi: ""
    })

    stationInfo = store.getState().app.stationInfo;
    // lon = store.getState().app.lon;
    // lat = store.getState().app.lat;
    // nearPoiId = store.getState().app.nearPoiId;

    // const [popTime, setPopTime] = useState("3");
    // const [count1, setCount1] = useState({ num: 0 });
    // const increament1 = useCallback(() => {
    //     setCount1({ num: count1.num + 1 });
    //   },[count1]);

    useEffect(() => {
        // alert(chargerList.infoList[0].operatorId);
        if (chargerList.infoList[0].operatorId === 'ME') {
            mixpanel("evcharge:/evcharge/easypayatype");
            logbox("/evcharge/easypayatype", "");
        } else {
            mixpanel("evcharge:/evcharge/easypaybtype");
            logbox("/evcharge/easypaybtype", "");
        }
    }, [chargerList])

    useEffect(() => {
        getTapCharge(poi, lon, lat)
            .then(function (response: any) {

                const validCharge = response?.infoList?.filter(x => x.status === "CHARGING_STANDBY" || x.status === "UNCONFIRMED");

                if (validCharge.length === 0 || response.infoList.length === 0) {
                    // alert("사용가능한 충전기가 없습니다");
                    modal.alert(() => {
                        goBack();
                    }, "사용 가능한 <br/>충전기가 없습니다.",);
                } else {
                    setChargerList(response);
                    setRoadData(response?.roadMap);
                    setSeqList(response?.infoList);
                    setInited(true);
                }

                // getTapChargeRadius("1000", lon, lat, "")
                getTapChargeRadius("100", lon, lat, response.infoList[0].operatorId, poi)
                    .then((response: any) => {
                        // alert(JSON.stringify(response)); //ok
                        if (response.result === true) {
                            setNearStation(response.infoList);
                        }
                    })
            })
            .catch(function (error: any) {
                console.log(error);
                modal.alert(() => {
                    goBack();
                }, "해당 충전소는 <br/>서비스 오픈 준비중입니다.");
            })
    }, []);

    function seqSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    // useEffect(() => {
    //     //   console.log('seqList : ', seqList);
    //     //   console.log('stationInfo : ', stationInfo);

    //     if (seqList === undefined || stationInfo === undefined) return;

    //     const temp = stationInfo?.ev_chargers.map((item) => seqList.map((subItem) => subItem?.chargerId === (item?.station_id + item?.chargerId) ? { ...item, couplerSeq: subItem?.couplerSeq, station_image: subItem?.stationImagePath } : { ...item }));

    //     //   console.log('temp : ', temp);

    //     const temp1 = temp.map((item) => _.remove(item, (obj: any) => { return obj.couplerSeq }));

    //     const temp2 = temp1.map((item, index) => item[0] !== undefined ? item[0] : stationInfo.ev_chargers[index]);

    //     const temp3 = temp2.sort((a, b) => { return a.chargerId - b.chargerId })

    //     const seqNone = seqList?.filter((item) => (item.couplerSeq === undefined || item.couplerSeq === ""));

    //     if (seqNone.length > 0) {
    //         setSeqYN(true);
    //     }

    //     setChargerdata(temp3);
    // }, [seqList]);

    const goBack = () => {
        if (store.getState().app.poiDetailStatus.toString() === "Y") {
            tmapInterface.onBackKeyPressed();
        } else {
            props.history.replace('/main');
        }
    }

    const goOpen = () => {
        mixpanel("evcharge:/evcharge/easypayatype:tap.picture");
        logbox("/evcharge/easypayatype", "tap.picture");
        setImgOpen(true);
    }

    function chargerTypes(chargerdata, data) {
        switch (chargerdata) {
            case "AC_SINGLE_PHASE":
                // return <><span className={data === true ? "type4":"type4_dim"}>AC 완속</span></>
                return <><span className="type4">AC 완속</span></>
            case "DC_CHADEMO_AND_AC_THREE_PHASE_AND_DC_COMBO":
                return <><span className="type2">DC 차데모</span> <span className="type3">AC 3상</span> <span className="type1">DC 콤보</span></>
            case "DC_COMBO":
                return <><span className="type1">DC 콤보</span></>
            case "DC_CHADEMO_AND_DC_COMBO":
                return <><span className="type2">DC 차데모</span> <span className="type1">DC 콤보</span></>
            case "DC_CHADEMO_AND_AC_THREE_PHASE":
                return <><span className="type2">DC 차데모</span> <span className="type3">AC 3상</span></>
            case "DC_CHADEMO":
                return <><span className="type2">DC 차데모</span></>
            case "AC_THREE_PHASE":
                return <><span className="type3">AC 3상</span></>
        }
    }

    function Caltime(data) {
        const caltime: string = data;
        // alert(caltime);

        if (caltime.includes('일') || caltime.includes('하루')) {
            return (
                <span className="time color2">{caltime}</span>
            )
        } else if (caltime.includes('시간') || caltime.includes('분')) {
            return (
                <span className="time color1">{caltime}</span>
            )
        } else if (caltime.includes('달')) {
            return (
                <span className="time color3">{caltime}</span>
            )
        } else {
            return (
                <span className="time color2">사용 내역 없음</span>
            )
        }
    }

    const goChargeCurrentStatus = () => {
        if (chargerList.infoList[0].operatorId === 'ME') {
            mixpanel("evcharge:/evcharge/easypayatype:tap.chargestatus");
            logbox("/evcharge/easypayatype", "tap.chargestatus");
        } else {
            mixpanel("evcharge:/evcharge/easypaybtype:tap.chargestatus");
            logbox("/evcharge/easypaybtype", "tap.chargestatus");
        }

        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    if (response.activeSessionCount === 0) {
                        mixpanel("evcharge:/evcharge/main:view.nousecharge_popup");
                        logbox("/evcharge/main", "view.nousecharge_popup");
                        modal.alert(() => {
                            mixpanel("evcharge:/evcharge/main:tap.nousecharge_ok");
                            logbox("/evcharge/main", "tap.nousecharge_ok");
                        }, "사용 중인 충전기가 없습니다.");
                    } else if (response.chargeSessionGuid) {
                        store.dispatch(appActions.setGuid(response.chargeSessionGuid));

                        getAuthStatus(response.chargeSessionGuid)
                            .then(function (response2: any) {
                                store.dispatch(appActions.setRemoteOffYn(response2.remoteOffYn));
                                store.dispatch(appActions.setChargerSpeed(response2.chargerSpeed));

                                props.history.push(`/charging/${response.chargeSessionGuid}`);
                            })
                    } else if (response.unpaidGuid) {
                        store.dispatch(appActions.setGuid(response.chargeSessionGuid));

                        modal.alert(() => {
                            props.history.replace("/nonPay");
                        }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
                    } else if (response.authSessionGuid) {
                        store.dispatch(appActions.setGuid(response.authSessionGuid));

                        getAuthStatus(response.authSessionGuid)
                            .then(function (response2: any) {
                                store.dispatch(appActions.setRemoteOffYn(response2.remoteOffYn));
                                store.dispatch(appActions.setChargerSpeed(response2.chargerSpeed));

                                if (response2.authStatus === 1 || response2.authStatus === 2) {
                                    store.dispatch(appActions.setQrStatus("1"));
                                    props.history.replace("/qrAuth");
                                } else if (response2.authStatus === 4 || response2.authStatus === 6) {
                                    props.history.push(`/charging/${response.authSessionGuid}`);
                                }
                            })
                    }
                }
            })
    }

    const goStartQrChagingView = () => {
        if (chargerList.infoList[0].operatorId === 'ME') {
            mixpanel("evcharge:/evcharge/easypayatype:tap.qrscan");
            logbox("/evcharge/easypayatype", "tap.qrscan");
        } else {
            mixpanel("evcharge:/evcharge/easypaybtype:tap.qrscan");
            logbox("/evcharge/easypaybtype", "tap.qrscan");
        }

        store.dispatch(appActions.setQrStatus(''));

        if (firstClick) return;
        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    if (response.unpaidGuid) {
                        store.dispatch(appActions.setGuid(response.unpaidGuid));
                        modal.alert(() => {
                            props.history.replace("/nonPay");
                        }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
                    } else if (response.activeSessionCount >= 3) {
                        mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
                        logbox("/evcharge/main", "view.allmultiuse_popup");

                        modal.alert(() => {
                            mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
                            logbox("/evcharge/main", "tap.allmultiuse_ok");
                        }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
                    } else {
                        popClosed_TESTQR();
                    }
                }
            })
    };

    const popClosed_TESTQR = () => {
        // PIN 번호 화면 호출
        tmapInterface.startQrCodeScanActivity(QrCodeScan);
    };

    function QrCodeScan(status: string, qrcode: string) {
        if (Const.DBG_OK) {
            alert("QrCode" + status + " " + JSON.stringify(qrcode));
        }
        //qr코드가 '' 빈값일때 qr인증실패로 이동
        if (status === "COMPLETE") {
            if (!qrcode) {
                props.history.replace("/authFail");
            } else {
                store.dispatch(appActions.setQrCode(qrcode));

                props.history.replace("/qrAuth");
            }
        } else if (status === "CANCEL") {
            mixpanel("evcharge:/evcharge/qrscan:tap.back");
            logbox("/evcharge/qrscan", "tap.back");
        }
    }

    const goTapChargeAuth = (accessKey: any, data) => {
        store.dispatch(appActions.setEasyPayData(data));
        console.log('detaildata', data);

        props.history.replace("/tapChargeAuth");
    };

    const goCharge = (chargeData: any) => {
        store.dispatch(appActions.setCpoCode(chargerList.infoList[0].operatorId));

        if (chargerList.infoList[0].operatorId === 'ME') {
            mixpanel("evcharge:/evcharge/easypayatype:tap.easypayatypeselect_ok");
            logbox("/evcharge/easypayatype", "tap.easypayatypeselect_ok");
        } else {
            mixpanel("evcharge:/evcharge/easypaybtype:tap.easypaybtypeselect_ok");
            logbox("/evcharge/easypaybtype", "tap.easypaybtypeselect_ok");
        }

        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    if (response.unpaidGuid) {
                        store.dispatch(appActions.setGuid(response.unpaidGuid));
                        modal.alert(() => {
                            props.history.replace("/nonPay");
                        }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
                    } else if (response.activeSessionCount >= 3) {
                        mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
                        logbox("/evcharge/main", "view.allmultiuse_popup");

                        modal.alert(() => {
                            mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
                            logbox("/evcharge/main", "tap.allmultiuse_ok");
                        }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
                    } else {
                        goTapChargeAuth(globalVars.accessKey, chargeData);
                    }
                }
            })
    }

    const cancelCharge = () => {
        setFirstClick(false);
        if (chargerList.infoList[0].operatorId === 'ME') {
            mixpanel("evcharge:/evcharge/easypaybtype:tap.easypayatypeselect_quit");
            logbox("/evcharge/easypaybtype", "tap.easypayatypeselect_quit");
        } else {
            mixpanel("evcharge:/evcharge/easypaybtype:tap.easypaybtypeselect_quit");
            logbox("/evcharge/easypaybtype", "tap.easypaybtypeselect_quit");
        }

        setFocusCharger("");
        setBPop(false);
    }

    const clickEvent = (data) => {
        setFocusCharger(data.chargerId);
    }

    const goInfo = () => {
        if (chargerList.infoList[0].operatorId === 'ME') {
            mixpanel("evcharge:/evcharge/easypayatype:tap.easypayuseguide");
            logbox("/evcharge/easypayatype", "tap.easypayuseguide");
        } else {
            mixpanel("evcharge:/evcharge/easypaybtype:tap.easypayuseguide");
            logbox("/evcharge/easypaybtype", "tap.easypayuseguide");
        }

        props.history.push(`/tapChargeInfo`);
    }

    const goTapcharge = (poi: string, lon: string, lat: string) => {
        if (chargerList.infoList[0].operatorId === 'ME') {
            mixpanel("evcharge:/evcharge/easypayatype:tap.near");
            logbox("/evcharge/easypayatype", "tap.near");
        } else {
            mixpanel("evcharge:/evcharge/easypaybtype:tap.near");
            logbox("/evcharge/easypaybtype", "tap.near");
        }

        props.history.replace(`/tapCharge/${poi}/${lon}/${lat}`);
    }

    const Qrstyle = {
        height: 24,
        width: 24,
        marginRight: 5,
        marginTop: 1
        // right:"3px",
        // position:"relative"
    };

    if (!inited) {
        return null;
    } else {
        return (
            <>
                {(imgOpen)
                    ?
                    <div
                        style={{ position: "absolute", width: "100%", height: "100%", zIndex: "99999999", backgroundColor: "#000" }}
                    >
                        <img src={require('%/images/common/ic-top-close-white.svg').default} alt="" style={{ position: "absolute", top: "4%", left: "8%", transform: "translate(-50%, -50%)", zIndex: "99999" }} onClick={() => { setImgOpen(false); setImgZoomStart(false); }} />
                        <TransformWrapper
                            initialScale={1}
                            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                            zoomAnimation={{ size: 0 }}
                            onZoomStart={() => setImgZoomStart(true)}
                        >
                            <TransformComponent
                                wrapperStyle={imgZoomStart ? { height: "100%" } : { top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
                            >
                                <img src={(seqList[0]?.stationImagePath)}
                                // style={{marginTop:"250px"}}
                                //  style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}
                                />
                            </TransformComponent>
                        </TransformWrapper>

                        {/* <img src={"https://t1.daumcdn.net/cfile/blog/2455914A56ADB1E315"} style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}/> */}
                    </div>
                    :
                    <>
                        <div className='tap-header' style={{ boxShadow: "0px 10px 10px -1px #fff" }}>
                            <div className='top'>
                                <img src={require('%/images/common/arrow-left.svg').default} alt="" style={{ width: "30px", position: "relative", left: "10px" }} onClick={() => {
                                    mixpanel("evcharge:/evcharge/easypayatype:tap.back");
                                    logbox("/evcharge/easypayatype", "tap.back");

                                    goBack();
                                }} />
                                <span className='charge-info' onClick={() => goInfo()}>이용안내</span>
                            </div>
                            {(chargerList.infoList[0].operatorId === 'ME')
                                ? (
                                    (!seqList[0]?.stationImagePath || seqList.length <= 0) ? (
                                        chargerList.infoList.length < 10 ? (
                                            <div className="picture">
                                                <img
                                                    src={require(`%/images/logo/img-ev-station-rapid-0${chargerList.infoList.length}.svg`).default}
                                                    alt=""
                                                />
                                            </div>
                                        ) : (
                                            <div className="picture">
                                                <img
                                                    src={require("%/images/logo/img-ev-station-rapid-10.svg").default}
                                                    alt=""
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <div className="picture">
                                            <img
                                                src={seqList[0]?.stationImagePath}
                                                style={{ height: Number(window.innerWidth) / 2.57, objectFit: "cover" }}
                                            />
                                        </div>
                                    )
                                ) : chargerList.infoList[0].operatorId === 'PC' && isPolestar ? (
                                    <div className="picture">
                                        <img
                                            src={require("%/images/logo/img_ev_pc_polestart_station.svg").default}
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    <div className="picture">
                                        <img
                                            src={require("%/images/logo/img-ev-station-slow-sticker.svg").default}
                                            alt=""
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className="charging-station">
                            {(chargerList.infoList[0].operatorId === 'ME')
                                ?
                                (<>
                                    {/* <button type="button" className="back-btn" onClick={() => {
                                    mixpanel("evcharge:/evcharge/easypayatype:tap.back");
                                    logbox("/evcharge/easypayatype", "tap.back");

                                    goBack();
                                }}>
                                    <img src={require('%/images/btn-arrow-bg-black.svg').default} alt="" />
                                </button> */}
                                    {
                                        (!seqList[0]?.stationImagePath || seqList.length <= 0)
                                            ?
                                            <></>
                                            :
                                            <button type="button" className="zoom-btn" onClick={goOpen} style={{ top: `${Number(window.innerWidth) / 2.8}px` }}>
                                                <img src={require('%/images/common/btn-map-full-black.svg').default} alt="" />
                                            </button>
                                    }
                                </>
                                )
                                :
                                (
                                    <></>
                                    // <button type="button" className="back-btn" onClick={() => {
                                    //     mixpanel("evcharge:/evcharge/easypaybtype:tap.back");
                                    //     logbox("/evcharge/easypaybtype", "tap.back");

                                    //     goBack();
                                    // }}>
                                    //     <img src={require('%/images/btn-arrow-bg-black.svg').default} alt="" />
                                    // </button>
                                )
                            }

                            <div className="title-group">
                                <img src={require(`%/images/cpo/ic-list-evstation-${(chargerList.infoList[0].operatorId).toLowerCase()}@3x.png`).default} alt="" style={{ width: "45px", height: "45px" }} />
                                <div className='station-name'>
                                    {Number(window.innerWidth) <= 320
                                        ? utils.textLengthOverCut(chargerList.orgName, 15, "...")
                                        : utils.textLengthOverCut(chargerList.orgName, 19, "...")}
                                </div>
                                {!roadData ?
                                    <>
                                        <span>{chargerList.fullAddressJibun}</span>
                                    </>
                                    :
                                    <>
                                        <span>{roadData}</span>
                                    </>
                                }
                            </div>
                            {
                                // stationInfo.ev_charger_count_info[0].available_count === 0 && stationInfo.ev_charger_count_info[1].available_count === 0
                                //     ?
                                //     (
                                //         <div className="list">
                                //             <div className="nodata">
                                //                 <figure>
                                //                     <img src={require('%/images/img-no-available-charger.svg').default} alt="" />
                                //                 </figure>
                                //                 <p className="txt">
                                //                     모든 충전기가 ‘사용 중’으로<br />
                                //                     <em>현재 사용 가능한 기기가 없습니다.</em>
                                //                 </p>
                                //             </div>
                                //         </div>
                                //     )
                                //     :
                                (
                                    <>
                                        <div style={nearStation.length < 1 ? { paddingBottom: "100px" } : { paddingBottom: "20px" }}>
                                            <div className="list">
                                                <div className="ment">
                                                    <img src={require('%/images/common/taptap-info.svg').default} alt="" style={{ position: "relative", top: "3px", paddingRight: "10px" }} />
                                                    충전기를 선택하면 충전이 시작됩니다.
                                                </div>
                                            </div>
                                            {chargerList.infoList
                                                // .filter(data=> (data['station_id']+data['chargerId']).toString() == seqList.map(i=>i.chargerId))
                                                // .filter(data=> data.available === true)
                                                .map(data => {
                                                    if (data.operatorId === 'ME') {
                                                        return (
                                                            <div style={{ padding: '0 20px' }}>
                                                                <ul>
                                                                    <li onClick={() => {
                                                                        // if(data.available===true){
                                                                        mixpanel("evcharge:/evcharge/easypayatype:tap.connector");
                                                                        logbox("/evcharge/easypayatype", "tap.connector");
                                                                        mixpanel("evcharge:/evcharge/easypayatype:view.easypayatypeselect_popup");
                                                                        logbox("/evcharge/easypayatype", "view.easypayatypeselect_popup");
                                                                        // chkOperatorId(data);
                                                                        // goTapChargeAuth(globalVars.accessKey , data);
                                                                        setFirstClick(true);
                                                                        modal.alert(
                                                                            () => {
                                                                                goCharge(data);
                                                                            }, // 확인 버튼을 클릭할 때 실행될 콜백 함수
                                                                            '충전을 시작하시겠습니까?',   // 컨텐츠 제목 (선택 사항)
                                                                            "",
                                                                            true,
                                                                            () => cancelCharge()
                                                                        );
                                                                        // setBPop(true);
                                                                        // }
                                                                    }} onTouchStart={() => clickEvent(data)} onTouchEnd={() => setFocusCharger("")}
                                                                        style={data.chargerId === focusCharger ? { backgroundColor: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))" } : {}}>
                                                                        <em style={{ color: "#222" }}>{data.couplerSeq ? data.couplerSeq : data.chargerId}</em>
                                                                        <div className="txt">
                                                                            <div className="dc">
                                                                                <>{chargerTypes(data.type, data.available)}</>
                                                                            </div>
                                                                            <p className="info">
                                                                                <span className="kw type1" style={{ color: "#909090" }}>{data.powerType}</span>
                                                                                <span style={{ color: "#909090" }}> {window.innerWidth < 350 ? utils.textLengthOverCut(data.stationId + data.chargerId, 4, "...") : (data.stationId + data.chargerId)}</span>
                                                                            </p>
                                                                        </div>
                                                                        {
                                                                            data.available === true
                                                                                ?
                                                                                <>
                                                                                    {Caltime(moment(data.updateDateTime, 'YYYYMMDDHH').fromNow())}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {data.status === "CHARGING"
                                                                                        ?
                                                                                        <span className="time color1">사용중</span>
                                                                                        :
                                                                                        <span className="time color4">{data.status === "UNCONFIRMED" ? "알수없음" : "사용불가"}</span>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div style={{ padding: '0 20px' }}>
                                                                <ul>
                                                                    <li onClick={() => {
                                                                        // if(data.available===true){
                                                                        mixpanel("evcharge:/evcharge/easypaybtype:tap.connector");
                                                                        logbox("/evcharge/easypaybtype", "tap.connector");
                                                                        mixpanel("evcharge:/evcharge/easypaybtype:view.easypaybtypeselect_popup");
                                                                        logbox("/evcharge/easypaybtype", "view.easypaybtypeselect_popup");
                                                                        // chkOperatorId(data);
                                                                        // goTapChargeAuth(globalVars.accessKey, data);
                                                                        // clickEvent(data);
                                                                        // setBPop(true);
                                                                        modal.alert(
                                                                            () => {
                                                                                goCharge(data)
                                                                            }, // 확인 버튼을 클릭할 때 실행될 콜백 함수
                                                                            '충전을 시작하시겠습니까?',   // 컨텐츠 제목 (선택 사항)
                                                                            "",
                                                                            true,
                                                                            () => cancelCharge()
                                                                        );
                                                                        setFirstClick(true);
                                                                        // }
                                                                    }} onTouchStart={() => clickEvent(data)} onTouchEnd={() => setFocusCharger("")} className="finish"
                                                                        style={data.chargerId === focusCharger ? { backgroundColor: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))" } : {}}>
                                                                        <em style={(data.chargerId === focusCharger) ? { backgroundColor: "#fff", color: "#222" } : {}}>{data.couplerSeq ? data.couplerSeq : data.chargerId}</em>
                                                                        <div className="txt">
                                                                            <div className="dc">
                                                                                <>{chargerTypes(data.type, data.available)}</>
                                                                            </div>
                                                                            <p className="info">
                                                                                <span style={{ color: "#909090" }}>{data.powerType}</span>
                                                                                <span style={{ color: "#909090" }}>{data.stationId + data.chargerId}</span>
                                                                            </p>
                                                                        </div>
                                                                        {
                                                                            data.available === true
                                                                                ?
                                                                                <>
                                                                                    {Caltime(moment(data.updateDateTime, 'YYYYMMDDHH').fromNow())}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {data.status === "CHARGING"
                                                                                        ?
                                                                                        <span className="time color1">사용중</span>
                                                                                        :
                                                                                        <span className="time color4">{data.status === "UNCONFIRMED" ? "알수없음" : "사용불가"}</span>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </>
                                )
                            }

                            {
                                nearStation.length < 1 ?
                                    <></>
                                    :
                                    <div className='near-station' style={{ paddingBottom: "100px" }}>
                                        <div style={{ padding: "0 20px" }}>
                                            <div className='title'>찾으시는 충전기가 없으신가요?</div>
                                            <div className='sub'>100m 안에 탭탭차지를 사용할 수 있는 다른 충전소가 있어요.</div>
                                        </div>
                                        <div className="list-box">
                                            {(
                                                nearStation.map((x, i) => {
                                                    return (
                                                        <>
                                                            <div className="list-item"
                                                                onTouchStart={() => setPressed({ ...pressed, focusNear: true, focusPoi: x.poiId })}
                                                                onTouchEnd={() => setPressed({ ...pressed, focusNear: false })}
                                                                style={pressed.focusNear && x.poiId === pressed.focusPoi ? { background: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))" } : {}}
                                                            >
                                                                <div
                                                                    onClick={() => {
                                                                        store.dispatch(appActions.setNearPoiId(x.poiId));
                                                                        store.dispatch(appActions.setLon(x.lon));
                                                                        store.dispatch(appActions.setLat(x.lat));
                                                                        goTapcharge(x.poiId, x.lon, x.lat);
                                                                    }}
                                                                >
                                                                    <p className="addr">
                                                                        {Math.floor(x.distance) >= 1000
                                                                            ? Math.round(x.distance / 100) / 10 + "km"
                                                                            : Math.floor(x.distance) + "m"}{" "}
                                                                    </p>
                                                                    <h4>
                                                                        {x.stationName}
                                                                        <img src={require('%/images/common/ic-common-arrow.svg').default} style={{ position: "relative", float: "right", width: "20px" }} />
                                                                    </h4>
                                                                    <p className="result">
                                                                        {x.availableSuperFast !== 0 ?
                                                                            <>
                                                                                {/* <span className="type1" /> */}
                                                                                <span className="type7" style={{ fontFamily: "TMOBI300" }} />
                                                                                <span className="type8" style={{ paddingLeft: "25px", fontFamily: "TMOBI300" }}>
                                                                                    {
                                                                                        // x.ev_charger_count_info[2]
                                                                                        //     ?.available_count
                                                                                        x.availableSuperFast
                                                                                    }
                                                                                    대 가능
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                        {x.availableFast !== 0 ?
                                                                            <>
                                                                                {x.availableSuperFast !== 0 ?
                                                                                    <span className="type2" />
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                                <span className="type3" style={{ fontFamily: "TMOBI300" }} />
                                                                                <span
                                                                                    className="type4"
                                                                                    style={{ fontFamily: "TMOBI300" }}
                                                                                >
                                                                                    {
                                                                                        // x.ev_charger_count_info[1]
                                                                                        //     ?.available_count
                                                                                        x.availableFast
                                                                                    }
                                                                                    대 가능
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                        {x.availableSlow !== 0 ?
                                                                            <>
                                                                                {x.availableFast !== 0 ?
                                                                                    <span className="type2" />
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                                <span className="type5" style={{ fontFamily: "TMOBI300" }} />
                                                                                <span
                                                                                    className="type6"
                                                                                    style={{ fontFamily: "TMOBI300" }}
                                                                                >
                                                                                    {
                                                                                        // x.ev_charger_count_info[0]
                                                                                        //     ?.available_count
                                                                                        x.availableSlow
                                                                                    }
                                                                                    대 가능
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div style={borderBottomStyle} />
                                                        </>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>
                            }
                            <div className="button-group">
                                <button type="button" onClick={goChargeCurrentStatus} onTouchStart={() => setFocusCurrentStatus(true)} onTouchEnd={() => setFocusCurrentStatus(false)}
                                    style={focusCurrntStatus ? { filter: "brightness(90%)", width: "30%" } : { width: "30%" }}>
                                    {" "}
                                    충전현황
                                </button>
                                <button type="button" onClick={goStartQrChagingView} onTouchStart={() => setFocusCharging(true)} onTouchEnd={() => setFocusCharging(false)}
                                    style={{ filter: focusCharging ?"brightness(90%)":"", width: "68%", left: "2%", backgroundColor: (isPolestar && chargerList.infoList[0].operatorId=="PC") ? "#171819":"#0064ff", color: "#fff" }}>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Lottie animationData={QrAnimation} style={Qrstyle} />
                                        <span style={{ fontFamily: "TMOBI500" }}>충전 시작하기</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {/* <div className="bottom-popup" style={{ visibility: bPop ? 'visible' : 'hidden' }}>
                    <div className="popup-wrap" style={{ left: "10%", bottom: "40%", width: "80%", borderRadius:"10px", boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.14)" }}>
                        <div className="popup-contents">
                            <p style={window.innerWidth < 340 ? { fontWeight: "normal", fontSize: "15px", padding: "20px 0" } : { fontWeight: "normal", padding: "20px 0" }}>충전을 시작하시겠습니까?</p>
                        </div>
                        <div className="popup-bottom">
                            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px", borderBottomLeftRadius: "10px" } : { borderBottomLeftRadius: "10px" }} onClick={cancelCharge}>취소</button>
                            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px", borderBottomRightRadius: "10px" } : { borderBottomRightRadius: "10px" }} onClick={goCharge}>확인</button>
                        </div>
                    </div>
                </div> */}
            </>
        )
    }
}

export { TapCharge };