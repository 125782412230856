/**-------------------------------------------------------------
1.2.1.5 충전소 인식 화면_3
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/charger1.html
-------------------------------------------------------------*/

/* eslint-disable */

import { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from "react-router";

import { globalVars } from '../../libs/global-vars';

import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'

import Const from '../../store/ev';
import { appActions, store } from '../../store';
import { patchQrAuth, postQrAuth } from '../../apis/auth';
import { getAuthStatus } from '../../apis/status';

let scrollCache = 0;
let timer: Nullable<number> = null;
let qrTimer: Nullable<number> = null;
let qrType: any = {};

const dispatch = store.dispatch;
// const ENV_COUPLER_TYPES = ["03", "05", "06"];
// const KE_COUPLER_TYPES = ["6", "7", "8", "9"];
// const SE_COUPLER_TYPES = ["DC_CHADEMO_AND_AC_THREE_PHASE", "DC_CHADEMO_AND_DC_COMBO", "DC_CHADEMO_AND_AC_THREE_PHASE_AND_DC_COMBO"];

function QrAuth(props: RouteComponentProps<{ id: string }>) {
	const [inited, setInited] = useState(false);
	const [stationName, setstationName] = useState('');
	const [qrWait, setQrWait] = useState(false);

	const localStorageItem = window.localStorage.getItem("multiCouplerData");
	const multiCouplerData = localStorageItem !== null ? JSON.parse(localStorageItem) : [];

	const history = useHistory();

	qrType = store.getState().app.qrType;

	const cacheScroll = useCallback(() => {
		// 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
		if (!/\/voice\/?$/.test(window.location.pathname)) return;
		scrollCache = window.scrollY;
	}, []);

	// EV충전 상태조회
	const checkConnectStatus = () => {
		const qrStatus = store.getState().app.qrStatus;
		const guid = store.getState().app.guid;

		if (globalVars.connCheckLoop != null) {
			clearInterval(globalVars.connCheckLoop);
		}

		//재진입인 경우
		if (qrStatus === '1') {
			setQrWait(true);

			globalVars.connCheckLoop = setInterval(() => {

				getAuthStatus(guid)
					.then((response: any) => {
						// const response = response.data;
						if (response.couplerType.length > 1) { //멀티 커플러인 경우
							clearInterval(globalVars.connCheckLoop);

							history.push({
								pathname: `/qrMultiCoupler`,
								props: {
									couplerData: [{
										chargerType: response.couplerType,
										guid: guid,
										qrCode: multiCouplerData.filter((item) => item.guid == guid)[0]?.qrCode
									}]
								}
							})
						} else {
							if (response.authStatus === 4 || response.authStatus === 6) { //성공
								
								clearInterval(globalVars.connCheckLoop);

								props.history.push(`/charging/${guid}`, store.getState().app.guid);

							} else if (response.authStatus === 3 || response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) { //실패
								clearInterval(globalVars.connCheckLoop);

								props.history.push(`/authFail/${response.cpoCode}`);

							}
						}
					}).catch((response) => {
						clearInterval(globalVars.connCheckLoop);

						console.log('Error!', response)
					});
			}, 2000); //2초에 한 번씩 조회
		} else if (qrStatus === '2') {
			setQrWait(true);

			globalVars.connCheckLoop = setInterval(() => {

				getAuthStatus(guid)
					.then((response: any) => {
						if (response.authStatus === 4 || response.authStatus === 6) { //성공
							// StopCnt = 0;
							clearInterval(globalVars.connCheckLoop);

							props.history.push(`/charging/${guid}`, store.getState().app.guid);

						} else if (response.authStatus === 3 || response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) { //실패
							clearInterval(globalVars.connCheckLoop);

							props.history.push(`/authFail/${response.cpoCode}`);

						}
					}).catch((response) => {
						console.log('Error!', response)
					});
			}, 2000); //2초에 한 번씩 조회
		} else {
			chkQrCode();
		}
	};

	// QR 체크
	const chkQrCode = () => {
		const qrCode = store.getState().app.qrCode;
		const accessKey = store.getState().app.accessKey;
		const authType = store.getState().app.qrType;

		if (Const.DBG_OK) {
			alert("chkQrCode - qrCode :: " + qrCode);
			alert("chkQrCode - accessKey :: " + accessKey);
		}

		var postQrAuthData = {
			qrCode: qrCode,
			authType: authType
		}

		postQrAuth(postQrAuthData)
			.then(function (response: any) {
				// alert(JSON.stringify(response));

				if (Const.DBG_OK) {
					console.log('GUID 획득 성공')
					alert('chkQrCode - DATA \n' + JSON.stringify(response.data))
				}

				if (response.result) {
					setstationName(response.stationName);

					store.dispatch(appActions.setCpoCode(response.cpoCode));

					if (Const.DBG_OK) {
						console.log('GUID 획득 성공')
						alert('chkQrCode - station_name \n' + stationName)
					}

					/***************************** 환경부 또는 차지비 충전기이고, 다중 커넥터이면 분기 처리 *******************************/
					if (response.chargerType.length > 1) {
						// if( (response.cpoCode === "ME" || response.cpoCode === "CV" ) && (ENV_COUPLER_TYPES.indexOf(response.chargerType) >= 0) ){
						// 3초간 충전소 정보 보여준 후, 커플러 선택 페이지 이동
						
						const addAuth = (tts) => {
							const newAuth = {
								qrCode: qrCode,
								guid: response.guid,
								expire: Date.now() + tts
							};
							
							const filterDeleteAuth = multiCouplerData.filter((item) => item.expire > Date.now());
							
							window.localStorage.setItem('multiCouplerData', JSON.stringify([newAuth, ...filterDeleteAuth]));
						};

						addAuth(120000);

						if (timer) clearTimeout(timer);
						timer = window.setTimeout(
							() => history.push({
								pathname: `/qrMultiCoupler`,
								props: {
									couplerData: [{
										chargerType: response.chargerType,
										guid: response.guid,
										accessKey: accessKey,
										qrCode: qrCode,
										cpoCode: response.cpoCode
									}]
								}
							}),
							// '/qrMultiCoupler?chargerType='+response.data.charger_type+'&guid='+response.data.guid+'&accessKey='+accessKey+'&qrCode='+qrCode+'&cpoCode='+response.data.belong_idx), 
							3000);
					}
					else {
						/***************************** 그렇지 않으면 ****************************/
						var patchQrAuthData = {
							guid: response.guid,
							couplerType: response.chargerType[0],
							qrCode: qrCode
						}

						patchQrAuth(patchQrAuthData)
							.then(function (response2: any) {

								if (qrTimer) clearTimeout(qrTimer);

								if (Const.DBG_OK) {
									alert('chkQrCode - updateCERT \n' + JSON.stringify(response2.data));
									console.log(JSON.stringify(response2.data));
								}

								setTimeout(() => {
									setQrWait(true);

									globalVars.connCheckLoop = setInterval(() => {

										getAuthStatus(response2.guid)
											.then((response3: any) => {
												if (response3.authStatus === 4 || response3.authStatus === 6) { //성공
													// StopCnt = 0;
													clearInterval(globalVars.connCheckLoop);

													dispatch(appActions.setGuid(response.guid));

													// 3초간 충전소 정보 보여주기
													props.history.push(`/charging/${response2.guid}`, store.getState().app.guid);

												} else if (response3.authStatus === 3 || response3.authStatus === 5 || response3.authStatus === 7 || response3.authStatus === 8) { //실패
													clearInterval(globalVars.connCheckLoop);

													props.history.push(`/authFail/${response3.cpoCode}`);
												}
											}).catch((response3) => {
												console.log('Error!', response3)
											});
									}, 2000); //2초에 한 번씩 조회
								}, 3000);
							})
							.catch(function (error2: any) {

								// if (qrTimer) clearTimeout(qrTimer);

								if (Const.DBG_OK) {
									alert('chkQrCode - authFail \n' + error2);
								}

								props.history.push('/authFail');
							});
					}
				} else {
					props.history.push('/authFail');
				}
			})
			.catch(function (error: any) {
				props.history.push('/authFail');
			});
	};

	useEffect(() => {
		window.scrollTo(0, scrollCache);
	}, [inited]);

	useEffect(() => {
		checkConnectStatus();
	}, []);

	if (0) {
		return (
			<>
				{/* <div id="page">
					<div className="qr-chager">
						<div className="txt-box">
							<p className="txt1">ㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎㅂㅇㅇㄴㅇㄷㅇㄴㅇㄷㅇㅈㄷㅇㅎㅇㄷㄴㅇㄷㅇㅊ</p>
							{ stationName!='' &&
								<p className="txt2">
									<span className="underline">충전기가 인식</span>되었습니다.
								</p>
							}
						</div>
					</div>
				</div> */}
			</>
		);
	} else {
		return (
			<>
				<Header isMenu={false} />
				<HandleBrowserBackButton />

				<div id="page">
					<div className="qr-chager">
						<div className="txt-box">
							{/* <p className="txt1"> {Number(window.innerWidth) <= 320 ? utils.textLengthOverCut(stationName, 6, '...') : utils.textLengthOverCut(stationName, 14, '...')} </p> */}
							{
								(stationName != '' && qrWait == false)
								?
								<>
									<p className="txt2">
										<span>충전기가 인식되었습니다.</span>
									</p>
									<p className="txt1">
										{<img src={require("%/images/common/ico_location_solid.svg").default}alt="" style={{verticalAlign:"sub", marginRight:"5px"}}/>}
										{<span>{stationName}</span>}
									</p>
								</>
								:
								<>
									<p className="txt2">
										<span>TMAP PLUG 인증 중입니다.</span>
									</p>
									<p className="txt1">
										<p><span>잠시만 기다려주세요.</span></p>
									</p>
								</>
							}
						</div>
					</div>
				</div>
			</>
		)
	};

	// render
	// if (!inited) return null

}

export { QrAuth };
