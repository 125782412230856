/* eslint-disable */


import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { GuardedRoute, GuardedRouteProps, GuardProvider } from 'react-router-guards';

import { GlobalLoading } from './components/GlobalLoading';
import { GlobalCover } 	 from './components/GlobalCover';
import { GlobalModal } 	 from './components/GlobalModal';

import { appManager } 				from './managers';
import { debugRoutes, DebugTools }  from './components/DebugTools';
import { HistoryManager } 			from './components/HistoryManager';
import { reset, requireUserId, voiceGuard, setInited } from './routeGuards';




//@EV 전기차 충전
import './styles/fonts/_fonts.css';
import './styles/layout/_style.css';
import './styles/swiper/swiper-bundle.min.css';
import './styles/layout/_style.scss';

// 공통
//import { Header } 		from './components/Layout';

import { Footer } 		from './components/Layout';
import { NotFoundPage } from './pages/Errors';

// 메인
import { EvDelivery, MainInfo, MainRouter, PayMethod, PoiSearch, PoiDetailRouter, TapCharge, DestinationCall, TapChargeInfo, Main, Maintenance, PncInfo} 	  	from './pages/main';
// 프로모션
import { EvPromotion, ShinhanPromotion, TapChargePromotion, DeliveryPromotion, FreeDeliveryPromotion, SpharosPromotion, RepayInfo, PlugLinkPromotion} from './pages/promotion'
// 인증
import { QrAuth, AuthFail, QrMultiCoupler, TapChargeMultiCoupler, TapChargeAuth} from './pages/auth'
// QR 충전
import { FinishCharge, Charging} from './pages/charge';
// 충전내역
import { ChargeHistory, ChargeHistoryDetail} from './pages/chargeHistory';
// 금액
import { NonPay, PayComplete } from './pages/pay';
// 쿠폰
import { PointCoupon, CouponRegister, CouponHistory, CouponInfo, RateCoupon } from './pages/coupon';
// CP
import { CP } from './pages/cp';
import { datadogRum } from './libs/datadog';


interface RouteConfig extends GuardedRouteProps {
  key: string;
  isSub?: boolean;
  backUrlAtFirstLanding?: string;
}

export const routes: RouteConfig[] = [
  /** @EV 전기차 충전*/
  // { key: 'main', 				path: '/main', 					exact: true, component: Main },
  { key: 'mainInfo', 			path: '/mainInfo', 				exact: true, component: MainInfo },
  { key: 'mainRouter', 			path: '/mainRouter', 				exact: true, component: MainRouter },
  { key: 'payMethod', 			path: '/payMethod', 				exact: true, component: PayMethod },
  { key: 'evDelivery', 			path: '/evDelivery', 				exact: true, component: EvDelivery },
  { key: 'poiSearch', 			path: '/poiSearch', 				exact: true, component: PoiSearch },
  { key: 'poiDetailRouter', 			path: '/poiDetailRouter', 				exact: true, component: PoiDetailRouter },
  { key: 'evPromotion', 			path: '/evPromotion', 				exact: true, component: EvPromotion },
  { key: 'shinhanPromotion', 			path: '/shinhanPromotion', 				exact: true, component: ShinhanPromotion },
  { key: 'deliveryPromotion', 			path: '/deliveryPromotion', 				exact: true, component: DeliveryPromotion },
  { key: 'freeDeliveryPromotion', 			path: '/freeDeliveryPromotion', 				exact: true, component: FreeDeliveryPromotion },
  { key: 'spharosPromotion', 			path: '/spharosPromotion', 				exact: true, component: SpharosPromotion },
  { key: 'repayInfo', 			path: '/repayInfo', 				exact: true, component: RepayInfo },
  { key: 'plugLinkPromotion', 			path: '/plugLinkPromotion', 				exact: true, component: PlugLinkPromotion },

  { key: 'qrAuth', 			path: '/qrAuth', 				exact: true, component: QrAuth },
  { key: 'authFail', 	path: '/authFail/:cpoCode?', exact: true, component: AuthFail },
  { key: 'qrMultiCoupler', 			path: '/qrMultiCoupler', exact: true, component: QrMultiCoupler },
  { key: 'tapChargeMultiCoupler', 			path: '/tapChargeMultiCoupler', exact: true, component: TapChargeMultiCoupler },
  { key: 'tapCharge',			path: '/tapCharge/:poi/:lon/:lat', 			exact: true, component: TapCharge },
  { key: 'destinationCall',			path: '/destinationCall', 			exact: true, component: DestinationCall },
  { key: 'tapChargeAuth',			path: '/tapChargeAuth', 			exact: true, component: TapChargeAuth },
  { key: 'tapChargeInfo',			path: '/tapChargeInfo', 			exact: true, component: TapChargeInfo },
  { key: 'tapChargePromotion', 			path: '/tapChargePromotion', 				exact: true, component: TapChargePromotion },

  { key: 'finishCharge',			path: '/finishCharge/:guid', 			exact: true, component: FinishCharge },
  { key: 'payComplete',	path: '/payComplete', 	exact: true, component: PayComplete },
  { key: 'charging',			path: '/charging/:guid/:pnc?', 			exact: true, component: Charging },
   

  { key: 'chargeHistory',		path: '/chargeHistory', 		exact: true, component: ChargeHistory }, 
  { key: 'chargeHistoryDetail',	path: '/chargeHistoryDetail/:guid/:repaidYn', 	exact: true, component: ChargeHistoryDetail },
   
  { key: 'nonPay',					path: '/nonPay', 					exact: true, component: NonPay },
   
  { key: 'pointCoupon',				path: '/pointCoupon', 				exact: true, component: PointCoupon },
  { key: 'couponRegister',      path: '/couponRegister/:productType', 		exact: true, component: CouponRegister },
  { key: 'couponHistory',		path: '/couponHistory/:coupon_idx', 			exact: true, component: CouponHistory },
  { key: 'couponInfo',			path: '/couponInfo', 				exact: true, component: CouponInfo },
  { key: 'main',			path: '/main', 				exact: true, component: Main },
  { key: 'rateCoupon',			path: '/rateCoupon', 				exact: true, component: RateCoupon },
  { key: 'maintenance',			path: '/maintenance', 				exact: true, component: Maintenance },
  { key: 'pncInfo',			path: '/pncInfo', 				exact: true, component: PncInfo },

  { key: 'cp',					path: '/cp/:idx', 					exact: true, component: CP },
  
  // 페이지 없음(마지막에 둘것)
  { key: 'notFound', path: '*', component: NotFoundPage }
];

function App() {
  datadogRum.getInternalContext();
  
  useEffect(() => {
    appManager.initialize();
  }, []);

  return (
    <>
      {
        <React.Suspense fallback="">
          <Router>
            <GlobalLoading />
            <GlobalCover />
            <GlobalModal />
            <DebugTools />
            <HistoryManager />
            {/* <HandleBrowserBackButton /> */}
    
            <GuardProvider guards={[reset, requireUserId, voiceGuard, setInited]}>
              {/*@헤더  <Header/> 는 각각 화면에서 Import*/}
              {/*@본문*/} 	
              <Switch>               
                
                <Route path="/" exact>
                  <Redirect to="/mainRouter" />
                </Route>
    
                {/* <Route path="/cp/*" exact>
                  <Redirect to="/cp" />
                </Route> */}
    
    
                {/* {debugRoutes} */}
                
                {routes.map(config => (
                  <GuardedRoute {...config} />
                ))}
              </Switch>
              
              {/*@푸터 - Version 표시로 사용 */}    
              {/* <Footer/>                 */}
              {/*@푸터 - Version 표시로 사용 */}    
            </GuardProvider>
          </Router>
        </React.Suspense>
      }
    </>
    
  );
}

export default App;
