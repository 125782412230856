/* eslint-disable */

import { useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { routes } from '../../App';
import { tmapInterface } from '../../libs/tmap-interface';
import { utils } from '../../libs/utils';
import { modal } from '../../components/GlobalModal';

import { getAuthStatus, getUserStatus } from '../../apis/status';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';

import { appActions, store } from "../../store";
import { globalVars } from '../../libs/global-vars';
import axios from 'axios';
import Const from '../../store/ev';

import topArrowBack from '../../assets/images/common/top_arrow_back.svg';
import { appManager } from '../../managers';

let belongIdx = "";
let userData: any = {}

const HeaderBackButton = withRouter(function ({ match, history }) {

  belongIdx = store.getState().app.cpoCode;

  const [bPop, setBPop] = useState(false); //쿠폰 등록 팝업
  const [qrBack, setQrBack] = useState(false); //인증화면 뒤로가기 팝업
  const [chargerPop, setChargerPop] = useState(false); //인증화면 팝업

  const [popTime, setPopTime] = useState("3");

  const targetRouteConfig = useMemo(() => {
    return routes.find(config => config.path === match.path);
  }, [match]);

  const cancelCharge = () => {
    setChargerPop(false);
  }

  // alert('HeaderBackButton \n' + match.path);

  const goHistoryBack = () => {
    back();
    if (match.path === "/couponRegister/:productType") {
      setBPop(true);

      modal.alert(
        () => goMain("coupon"),
        '쿠폰등록을 취소하시겠습니까?',
        "",
        true,
        () => cancelBack("coupon")
      );
      mixpanel("evcharge:/evcharge/addcoupon:view.couponcancel_popup");
      logbox("/evcharge/addcoupon", "view.couponcancel_popup");
    } else if (match.path === "/qrAuth" || match.path === "/tapChargeAuth" || match.path === "/qrMultiCoupler" || match.path === "/tapChargeMultiCoupler") {
      // setQrBack(true);
      modal.alert(
        () => goMain("charge"),
        "서비스 이용을 <br/>종료하시겠습니까?",
        "",
        true,
        () => cancelBack("qrback")
      );
      clearInterval(globalVars.connCheckLoop);
      clearInterval(globalVars.finishStatusCheckLoop);
    } else if (match.path === "/charging/:guid") {
      clearInterval(globalVars.authStatusCheckLoop);
      clearInterval(globalVars.chargeStatusCheckLoop);
      clearInterval(globalVars.progressStatusCheckLoop);
      clearInterval(globalVars.finishStatusCheckLoop);
      clearInterval(globalVars.chargeStopCheckLoop);
      clearInterval(globalVars.payStatusCheckLoop);
      clearInterval(globalVars.pinCheckLoop);
      goMain("charge");
    } else if (match.path === "/mainInfo" || match.path === "/pncInfo") {
      goMain("mainInfo")
    } else if (match.path === "/chargeHistoryDetail/:guid/:repaidYn") {
      history.replace('/chargeHistory');
    } else if (match.path === "/couponHistory/:coupon_idx") {
      history.replace('/pointCoupon')
    } else if (match.path === "/payComplete" || match.path === "/finishCharge") {
      history.replace('/main')
    } else if (match.path === "/evDelivery") {
      history.replace('/main')
    } else if (match.path === "/payMethod") {
      // history.replace('/main')
      goMain("payMethod")
    } else if (match.path === "/main") {
      confirmBack();
    }
    else {
      confirmBack();
    }
  }

  const cancelBack = (pageId: string) => {
    if (pageId === "coupon") {
      mixpanel("evcharge:/evcharge/addcoupon:tap.couponcancel_quit");
      logbox("/evcharge/addcoupon", "tap.couponcancel_quit");
      setBPop(false);
    } else if (pageId === "qrback") {
      setQrBack(false);
    }
  }

  const goMain = (pageId: String) => {
    if (pageId === "coupon") {
      mixpanel("evcharge:/evcharge/addcoupon:tap.couponcancel_ok");
      logbox("/evcharge/addcoupon", "tap.couponcancel_ok");
    }

    getUserStatus()
      .then(function (response: any) {
        if (response.result) {
          if (response.chargeSessionGuid || response.authSessionGuid) {
            history.replace("/main");
          } else if (response.unpaidGuid) {
            store.dispatch(appActions.setGuid(response.unpaidGuid));

            modal.alert(() => {
              history.replace("/nonPay");
            }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");

          } else {
            if (pageId === "coupon") {
              history.replace("/payMethod");
            } else {
              clearInterval(globalVars.connCheckLoop);
              history.replace("/main");
            }
          }
        }
      })
  }

  const back = () => { //메인 백버튼 logBox
    if (match.path === "/main") {
      mixpanel("evcharge:/evcharge/main:tap.back");
      logbox("/evcharge/main", "tap.back");
    } else if (match.path === "/mainInfo") {
      mixpanel("evcharge:/evcharge/chargeuseguide:tap.back");
      logbox("/evcharge/chargeuseguide", "tap.back");
    } else if (match.path === "/poiSearch") {
      mixpanel("evcharge:/evcharge/stationsearch:tap.back");
      logbox("/evcharge/stationsearch", "tap.back");
    } else if (match.path === "/evDelivery") {
      mixpanel("evcharge:/evcharge/chargedeliveryagree:tap.back");
      logbox("/evcharge/chargedeliveryagree", "tap.back");
    } else if (match.path === "/charger2") {
      mixpanel("evcharge:/evcharge/connectorwait:tap.back");
      logbox("/evcharge/connectorwait", "tap.back");
    } else if (match.path === "/destinationCall") {
      mixpanel("evcharge:/evcharge/qrscannavitype:tap.back");
      logbox("/evcharge/qrscannavitype", "tap.back");
    } else if (match.path === "/payMethod") {
      mixpanel("evcharge:/evcharge/paymethod:tap.back");
      logbox("/evcharge/paymethod", "tap.back");
    } else if (match.path === "/couponHistory/:coupon_idx") {
      mixpanel("evcharge:/evcharge/detailcoupon:tap.back");
      logbox("/evcharge/detailcoupon", "tap.back");
    } else if (match.path === "/couponInfo") {
      mixpanel("evcharge:/evcharge/couponuseguide:tap.back");
      logbox("/evcharge/couponuseguide", "tap.back");
    } else if (match.path === "/qrCharger1") {
      mixpanel("evcharge:/evcharge/charging:tap.back");
      logbox("/evcharge/charging", "tap.back");
    } else if (match.path === "/qrChargerSlow") {
      mixpanel("evcharge:/evcharge/charging:tap.back");
      logbox("/evcharge/charging", "tap.back");
    } else if (match.path === "/finishCharge") {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.back");
      logbox("/evcharge/paymentcomplete", "tap.back");
    } else if (match.path === "/payComplete") {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.back");
      logbox("/evcharge/paymentcomplete", "tap.back");
    } else if (match.path === "/chargeHistory") {
      mixpanel("evcharge:/evcharge/chargehistory:tap.back");
      logbox("/evcharge/chargehistory", "tap.back");
    } else if (match.path === "/chargeHistoryDetail/:guid") {
      mixpanel("evcharge:/evcharge/detailchargehistory:tap.back");
      logbox("/evcharge/detailchargehistory", "tap.back");
    } else if (match.path === "/tapChargeInfo") {
      mixpanel("evcharge:/evcharge/easypayuseguide:tap.back");
      logbox("/evcharge/easypayuseguide", "tap.back");
    } else if (match.path === "/qrMultiCoupler" || match.path === "/tapChargeMultiCoupler") {
      mixpanel("evcharge:/evcharge/selectconnector:tap.back");
      logbox("/evcharge/selectconnector", "tap.back");
    } else if (match.path === "/couponRegister/:productType") {
      mixpanel("evcharge:/evcharge/addcoupon:tap.back");
      logbox("/evcharge/addcoupon", "tap.back");
    }
    else {
      mixpanel("evcharge:/evcharge/main:tap.back");
      logbox("/evcharge/main", "tap.back");
    }
  }
  const confirmBack = useCallback(() => {
    // alert( 'confirmBack - ' + JSON.stringify(targetRouteConfig)) ;

    if (targetRouteConfig) {
      const { backUrlAtFirstLanding, isSub } = targetRouteConfig;

      if (utils.isFirstLanding() && backUrlAtFirstLanding) history.replace(backUrlAtFirstLanding);
      else if (isSub) history.goBack();
      else tmapInterface.onBackKeyPressed();

    } else {
      tmapInterface.onBackKeyPressed();
    }



  }, [history, targetRouteConfig]);

  return (
    <>
      <button type="button" className="button-back" onClick={goHistoryBack}></button>

      {/* <div className="bottom-popup" style={{ visibility: bPop ? 'visible' : 'hidden' }}> */}
      {/* <div className="popup-wrap">
          <div className="popup-contents">
            <p>쿠폰 추가를 취소하시겠습니까?</p>
          </div>
          <div className="popup-bottom">
            <button type="button" onClick={()=>cancelBack("coupon")}>취소</button>
            <button type="button" onClick={() => goMain("coupon")}>확인</button>
          </div>
        </div> */}

      {/* </div> */}

      {/* <div className="bottom-popup" style={{ visibility: qrBack ? 'visible' : 'hidden' }}>
        <div className="popup-wrap" style={{ left: "10%", bottom: "40%", width: "80%", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
          <div className="popup-contents" style={{ padding: "10px 0" }}>
            <p style={window.innerWidth < 340 ? { fontWeight: "normal", fontSize: "15px" } : { fontWeight: "normal" }}>서비스 이용을 종료하시겠습니까?</p>
          </div>
          <div className="popup-bottom">
            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px" } : {}} onClick={() => cancelBack("qrback")}>취소</button>
            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px" } : {}} onClick={() => goMain("charge")}>확인</button>
          </div>
        </div>
      </div> */}

      {/* <div className="bottom-popup" style={{ visibility: chargerPop ? 'visible' : 'hidden' }}>
        <div className="popup-wrap" style={{ left: "10%", bottom: "40%", width: "80%", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
          <div className="popup-contents">
            <p style={window.innerWidth < 340 ? { fontWeight: "normal", fontSize: "15px" } : { fontWeight: "normal" }}>충전기 인증시간 {popTime}분 동안</p>
            <p style={window.innerWidth < 340 ? { fontWeight: "normal", fontSize: "15px" } : { fontWeight: "normal" }}>서비스 재진입이 불가합니다.</p><br />
            <p style={window.innerWidth < 340 ? { fontWeight: "normal", fontSize: "15px" } : { fontWeight: "normal" }}>TMAP 메인화면으로</p>
            <p style={window.innerWidth < 340 ? { fontWeight: "normal", fontSize: "15px" } : { fontWeight: "normal" }}>이동하시겠습니까?</p>
          </div>
          <div className="popup-bottom">
            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px" } : {}} onClick={cancelCharge}>취소</button>
            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px" } : {}} onClick={goTMain}>확인</button>
          </div>
        </div>
      </div> */}
    </>
  );
});

export { HeaderBackButton };